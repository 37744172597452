import React from "react";
// import { HashLink as Link } from "react-router-hash-link";
import Flags from "./Flags";
import "./SubMenu.scss";

// import { List, ListItem } from "@material-ui/core";

const SubMenuLanguage = ({ open, currentLanguage, lang, onSelection }) => {
  const handleClick = (item) => {
    onSelection(item);
  };
  if (open) {
    return (
      // <List>
      <div className="subMenu">
        <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.45602 2.07039C8.63103 0.190386 11.369 0.190389 12.544 2.07039L20 14H0L7.45602 2.07039Z"
            fill="black"
          />
        </svg>

        <ul className="menuBox">
          {lang.map((itom) => {
            return (
              <li
                key={itom.navigationTitle}
                style={{ float: "none" }}
                onClick={() => handleClick(itom.link)}
              >
                <div className="cont">
                  <div className="flage">
                    <Flags language={itom.link}></Flags>
                    {/* <svg
                      id="emoji"
                      viewBox="0 0 72 72"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="color">
                        <rect
                          x="5"
                          y="17"
                          width="62"
                          height="38"
                          fill="#f1b31c"
                        />
                        <rect
                          x="5"
                          y="30"
                          width="62"
                          height="12"
                          fill="#d22f27"
                        />
                        <rect x="5" y="17" width="62" height="13" />
                      </g>
                      <g id="line">
                        <rect
                          x="5"
                          y="17"
                          width="62"
                          height="38"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </g>
                    </svg> */}
                  </div>
                  <div className="subCountry">
                    {/* <button
                    className={
                      currentLanguage === itom.link
                        ? "lagBtn selected"
                        : "lagBtn"
                    }
                    key={itom.navigationTitle}
                    onClick={() => handleClick(itom.link)}
                  > */}
                    {itom.navigationTitle}
                    {/* </button> */}
                  </div>
                </div>
              </li>
            );
          })}
          {/* {routes.title == test &&
            routes.children.map((route) => {
              return (
                <li key={route.title} style={{ float: "none" }}>
                  {console.log("test", route.link)}
                  <Link to={routes.link + "#" + route.link}>{route.title}</Link>
                </li>
              );
            })} */}
        </ul>
      </div>
      // </List>
    );
  } else {
    return "";
  }
};

export default SubMenuLanguage;

// import React from "react";
// import "./Language.scss";

// const Language = ({ currentLanguage, lang, onSelection }) => {
//   const handleClick = (item) => {
//     onSelection(item);
//   };
//   return (
//     <div className="box">
//       {lang.map((itom) => {
//         return (
//           <button
//             className={currentLanguage === itom.link ? "item selected" : "item"}
//             key={itom.navigationTitle}
//             onClick={() => handleClick(itom.link)}
//           >
//             {itom.navigationTitle}
//           </button>
//         );
//       })}
//     </div>
//   );
// };

// export default Language;
