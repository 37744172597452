import React from "react";

const Flags = ({ language }) => {
  if (language === "/") {
    return (
      <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="color">
          <rect x="5" y="17" width="62" height="38" fill="#f1b31c" />
          <rect x="5" y="30" width="62" height="12" fill="#d22f27" />
          <rect x="5" y="17" width="62" height="13" />
        </g>
        <g id="line">
          <rect
            x="5"
            y="17"
            width="62"
            height="38"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  } else if (language === "/fr/") {
    return (
      <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="color">
          <rect x="5" y="17" width="62" height="38" fill="#fff" />
          <rect x="5" y="17" width="21" height="38" fill="#1e50a0" />
          <rect x="46" y="17" width="21" height="38" fill="#d22f27" />
        </g>
        <g id="line">
          <rect
            x="5"
            y="17"
            width="62"
            height="38"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  } else if (language === "/tr/") {
    return (
      <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="color">
          <rect x="5" y="17" width="62" height="38" fill="#d22f27" />
          <path
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m40.64 33.05s3.052 4.019 3.052 4.019l-4.934-1.532 4.932-1.541s-3.046 4.025-3.046 4.025l-0.003536-4.972"
          />
          <path
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m31.29 44.64a8.643 8.643 0 1 1 3.958-16.34 11 11 0 1 0 0 15.38 8.715 8.715 0 0 1-3.958 0.9507z"
          />
        </g>
        <g id="line">
          <rect
            x="5"
            y="17"
            width="62"
            height="38"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  } else if ((language = "/en/")) {
    return (
      <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="color">
          <rect x="5" y="17" width="62" height="38" fill="#1e50a0" />
          <g>
            <polygon
              fill="#fff"
              points="40 28.856 40 32 50.181 32 67 21.691 67 17 59.346 17 40 28.856"
            />
            <polygon
              fill="#d22f27"
              points="67 17 67 17 63.173 17 40 31.203 40 32 43.482 32 67 17.586 67 17"
            />
            <polygon
              fill="#fff"
              points="59.347 55 67 55 67 55 67 50.308 50.182 40 40 40 40 43.143 59.347 55"
            />
            <polygon
              fill="#d22f27"
              points="67 55 67 52.653 46.355 40 41.568 40 66.042 55 67 55 67 55"
            />
            <polygon
              fill="#fff"
              points="32 43.144 32 40 21.819 40 5 50.309 5 55 12.654 55 32 43.144"
            />
            <polygon
              fill="#d22f27"
              points="5 55 5 55 8.827 55 32 40.797 32 40 28.518 40 5 54.414 5 55"
            />
            <polygon
              fill="#fff"
              points="12.653 17 5 17 5 17 5 21.692 21.818 32 32 32 32 28.857 12.653 17"
            />
            <polygon
              fill="#d22f27"
              points="5 17 5 19.347 25.646 32 30.432 32 5.958 17 5 17 5 17"
            />
            <rect x="5" y="31" width="62" height="10" fill="#fff" />
            <rect x="31" y="17" width="10" height="38" fill="#fff" />
            <rect x="5" y="33" width="62" height="6" fill="#d22f27" />
            <rect x="33" y="17" width="6" height="38" fill="#d22f27" />
          </g>
        </g>
        <g id="line">
          <rect
            x="5"
            y="17"
            width="62"
            height="38"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  } else {
    return (
      <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="line-supplement">
          <path
            fill="#000"
            d="m43.36 49.61a1 1 0 0 1-0.999-0.9746l-0.0732-2.865a4.328 4.328 0 0 1 1.611-2.757l0.27-0.2769a10.43 10.43 0 0 0 2.939-7.406 11.08 11.08 0 0 0-11.12-11.03 13.44 13.44 0 0 0-2.242 0.1909 10.94 10.94 0 0 0-5.866 18.25c0.7676 0.8052 1.732 1.817 1.732 3.004v2.866a1 1 0 0 1-2 0v-2.866c0-0.3867-0.7822-1.208-1.116-1.558l-0.0732-0.0767a12.94 12.94 0 0 1 6.961-21.59 15.46 15.46 0 0 1 2.604-0.2241 13.08 13.08 0 0 1 13.12 13.01 12.42 12.42 0 0 1-3.485 8.804l-0.3047 0.3111c-0.2793 0.2822-1.024 1.032-1.03 1.323l0.0732 2.84a1.001 1.001 0 0 1-0.9746 1.025z"
          />
          <path
            fill="#000"
            d="m31.5 51.88a0.5 0.5 0 0 1-0.5-0.5v-2.728a0.5 0.5 0 0 1 1 0v2.728a0.5 0.5 0 0 1-0.5 0.5z"
          />
          <path
            fill="#000"
            d="m34.5 51.88a0.5 0.5 0 0 1-0.5-0.5v-2.728a0.5 0.5 0 0 1 1 0v2.728a0.5 0.5 0 0 1-0.5 0.5z"
          />
          <path
            fill="#000"
            d="m37.5 51.88a0.5 0.5 0 0 1-0.5-0.5v-2.728a0.5 0.5 0 0 1 1 0v2.728a0.5 0.5 0 0 1-0.5 0.5z"
          />
          <path
            fill="#000"
            d="m40.5 51.88a0.5 0.5 0 0 1-0.5-0.5v-2.728a0.5 0.5 0 0 1 1 0v2.728a0.5 0.5 0 0 1-0.5 0.5z"
          />
          <path
            fill="#000"
            d="m37.41 45.39c0.3399 0.5742 1.201 0.06418 0.8613-0.51l-1.978-3.343c-0.08944-0.1605-0.2629-0.2556-0.3991-0.2446-0.1362-0.01095-0.3097 0.08412-0.3991 0.2446l-1.978 3.343c-0.3399 0.5742 0.5214 1.084 0.8613 0.51l1.516-2.561z"
          />
          <circle cx="31.32" cy="39.54" r="3" fill="#000" />
          <circle cx="40.68" cy="39.54" r="3" fill="#000" />
          <path
            fill="#000"
            d="m22.14 53.32a2.616 2.616 0 0 1-1.744-0.6514 3.057 3.057 0 0 1-0.885-2.558 3.264 3.264 0 0 1-1.881-0.6211 2.464 2.464 0 0 1-1.015-1.637 2.371 2.371 0 0 1 1.723-2.589 2.513 2.513 0 0 1 2.591 0.7324l1.56-1.56a0.5 0.5 0 0 1 0.707 0.7071l-1.978 1.978a0.4954 0.4954 0 0 1-0.4258 0.1416 0.5017 0.5017 0 0 1-0.3682-0.2573 1.524 1.524 0 0 0-1.81-0.78 1.399 1.399 0 0 0-1.008 1.483 1.476 1.476 0 0 0 0.62 0.978 2.36 2.36 0 0 0 1.802 0.3862 0.4855 0.4855 0 0 1 0.455 0.1543 0.5 0.5 0 0 1 0.1143 0.4668 2.265 2.265 0 0 0 0.4648 2.228 1.775 1.775 0 0 0 2.624-0.4522c0.0966-0.2139 0.3984-0.8769-0.8018-1.776a0.5 0.5 0 0 1-0.0537-0.7539l1.433-1.433a0.5 0.5 0 0 1 0.707 0.7071l-1.048 1.048a2.187 2.187 0 0 1 0.6719 2.626 2.641 2.641 0 0 1-1.867 1.371 2.867 2.867 0 0 1-0.5856 0.0615z"
          />
          <path
            fill="#000"
            d="m22.5 27.49a0.498 0.498 0 0 1-0.3535-0.1465l-1.382-1.381a2.955 2.955 0 0 1-2.358 0.7583 2.378 2.378 0 0 1-1.722-2.609 2.46 2.46 0 0 1 1.013-1.619 3.264 3.264 0 0 1 1.881-0.6191 3.053 3.053 0 0 1 0.8818-2.555 2.52 2.52 0 0 1 2.047-0.6113 3.123 3.123 0 0 1 2.119 1.327 2.204 2.204 0 0 1-0.746 2.834c0.3056 0.2925 0.7324 0.71 1.238 1.259a0.5 0.5 0 1 1-0.7343 0.6777c-0.6114-0.6626-1.106-1.129-1.4-1.408l-0.2237-0.2148a0.5 0.5 0 0 1 0.0821-0.7734c0.0146-0.01 1.483-0.9771 0.94-1.837a2.105 2.105 0 0 0-1.413-0.8735 1.509 1.509 0 0 0-1.244 0.3672 2.235 2.235 0 0 0-0.4619 2.226 0.5 0.5 0 0 1-0.1143 0.4663 0.4935 0.4935 0 0 1-0.4551 0.1548 2.352 2.352 0 0 0-1.801 0.3847 1.467 1.467 0 0 0-0.6191 0.9585 1.404 1.404 0 0 0 1.007 1.504c0.5078 0.149 1.413-0.5014 1.77-0.8378a0.5 0.5 0 0 1 0.6972 0.01l1.706 1.706a0.5 0.5 0 0 1-0.3535 0.8535z"
          />
          <path
            fill="#000"
            d="m49.79 53.32a2.869 2.869 0 0 1-0.5869-0.0615 2.641 2.641 0 0 1-1.865-1.367 2.191 2.191 0 0 1 0.67-2.63l-1.048-1.048a0.5 0.5 0 0 1 0.707-0.7071l1.434 1.434a0.5 0.5 0 0 1-0.0547 0.7529c-1.199 0.9-0.8984 1.562-0.8 1.78a1.776 1.776 0 0 0 2.626 0.4453 2.262 2.262 0 0 0 0.4609-2.225 0.5 0.5 0 0 1 0.1143-0.4668 0.4888 0.4888 0 0 1 0.4551-0.1543 2.372 2.372 0 0 0 1.802-0.3857 1.47 1.47 0 0 0 0.6182-0.958 1.402 1.402 0 0 0-1.006-1.504 1.526 1.526 0 0 0-1.81 0.78 0.5007 0.5007 0 0 1-0.7939 0.1167l-0.2187-0.2271c-0.292-0.3076-0.837-0.88-1.61-1.604a0.5 0.5 0 1 1 0.6816-0.7305c0.6377 0.5952 1.123 1.09 1.444 1.425a2.506 2.506 0 0 1 2.581-0.7226 2.376 2.376 0 0 1 1.721 2.61 2.459 2.459 0 0 1-1.013 1.617 3.271 3.271 0 0 1-1.88 0.62 3.054 3.054 0 0 1-0.8819 2.555 2.629 2.629 0 0 1-1.747 0.6549z"
          />
          <path
            fill="#000"
            d="m49.63 27.42a0.5 0.5 0 0 1-0.3535-0.8535l1.636-1.637a0.5014 0.5014 0 0 1 0.6983-0.0112c0.3564 0.3369 1.253 0.9878 1.772 0.8383a1.399 1.399 0 0 0 1.008-1.483 1.476 1.476 0 0 0-0.6221-0.979 2.36 2.36 0 0 0-1.801-0.3847 0.5 0.5 0 0 1-0.5693-0.6216 2.265 2.265 0 0 0-0.4649-2.228 1.51 1.51 0 0 0-1.24-0.3638 2.107 2.107 0 0 0-1.416 0.877c-0.541 0.8564 0.9277 1.824 0.9424 1.834a0.5 0.5 0 0 1 0.082 0.7734l-1.501 1.501a0.5 0.5 0 0 1-0.707-0.7071l1.097-1.098a2.208 2.208 0 0 1-0.7578-2.84 3.122 3.122 0 0 1 2.122-1.33 2.522 2.522 0 0 1 2.043 0.6079 3.058 3.058 0 0 1 0.8857 2.559 3.264 3.264 0 0 1 1.88 0.6191 2.469 2.469 0 0 1 1.016 1.638 2.371 2.371 0 0 1-1.723 2.59 2.962 2.962 0 0 1-2.36-0.7583l-1.312 1.313a0.4981 0.4981 0 0 1-0.354 0.1463z"
          />
        </g>
        <g id="color">
          <rect x="5" y="17" width="62" height="38" />
          <path
            fill="#fff"
            d="m35.2 23.42c-10.3-0.09444-14.89 12.67-7.6 19.66l0.0682 0.0682c0.7569 0.6775 1.262 1.592 1.433 2.593v2.866c0.0044 0.3032-0.9173 0.6422-1.221 0.6379 2.957 2.114 12.51 2.93 16.38-0.2392-1.689-2.388-2.894-2.011-0.651-3.996 9.338-8.941 1.889-21.5-8.414-21.59z"
          />
          <path
            fill="#3f3f3f"
            d="m43.36 49.61a1 1 0 0 1-0.999-0.9746l-0.0732-2.865a4.328 4.328 0 0 1 1.611-2.757l0.27-0.2769a10.43 10.43 0 0 0 2.939-7.406 11.08 11.08 0 0 0-11.12-11.03 13.44 13.44 0 0 0-2.242 0.1909 10.94 10.94 0 0 0-5.866 18.25c0.7676 0.8052 1.732 1.817 1.732 3.004v2.866a1 1 0 0 1-2 0v-2.866c0-0.3867-0.7822-1.208-1.116-1.558l-0.0732-0.0767a12.94 12.94 0 0 1 6.961-21.59 15.46 15.46 0 0 1 2.604-0.2241 13.08 13.08 0 0 1 13.12 13.01 12.42 12.42 0 0 1-3.485 8.804l-0.3047 0.3111c-0.2793 0.2822-1.024 1.032-1.03 1.323l0.0732 2.84a1.001 1.001 0 0 1-0.9746 1.025z"
          />
          <path
            fill="#3f3f3f"
            d="m31.5 51.88a0.5 0.5 0 0 1-0.5-0.5v-2.728a0.5 0.5 0 0 1 1 0v2.728a0.5 0.5 0 0 1-0.5 0.5z"
          />
          <path
            fill="#3f3f3f"
            d="m34.5 51.88a0.5 0.5 0 0 1-0.5-0.5v-2.728a0.5 0.5 0 0 1 1 0v2.728a0.5 0.5 0 0 1-0.5 0.5z"
          />
          <path
            fill="#3f3f3f"
            d="m37.5 51.88a0.5 0.5 0 0 1-0.5-0.5v-2.728a0.5 0.5 0 0 1 1 0v2.728a0.5 0.5 0 0 1-0.5 0.5z"
          />
          <path
            fill="#3f3f3f"
            d="m40.5 51.88a0.5 0.5 0 0 1-0.5-0.5v-2.728a0.5 0.5 0 0 1 1 0v2.728a0.5 0.5 0 0 1-0.5 0.5z"
          />
          <path
            fill="#3f3f3f"
            d="m37.41 45.39c0.3399 0.5742 1.201 0.06418 0.8613-0.51l-1.978-3.343c-0.08944-0.1605-0.2629-0.2556-0.3991-0.2446-0.1362-0.01095-0.3097 0.08412-0.3991 0.2446l-1.978 3.343c-0.3399 0.5742 0.5214 1.084 0.8613 0.51l1.516-2.561z"
          />
          <circle cx="31.32" cy="39.54" r="3" fill="#3f3f3f" />
          <circle cx="40.68" cy="39.54" r="3" fill="#3f3f3f" />
          <path
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m49.63 26.91 1.637-1.637s1.188 1.098 2.143 0.8251c0.8972-0.2526 1.476-0.9915 1.364-1.917-0.3393-1.318-1.661-1.96-2.979-1.622 0.3103-0.9535 0.1181-2.044-0.5706-2.772-1.095-0.8427-2.483-0.5794-3.216 0.5923-0.8187 1.296 0.9402 2.437 0.9402 2.437l-1.436 1.544z"
          />
          <path
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m22.87 44.84-1.978 2.043c-0.4447-0.8727-1.449-1.303-2.388-1.023-0.8976 0.2522-1.369 1.013-1.257 1.939 0.3393 1.319 1.531 1.961 2.872 1.774-0.2561 1.019 0.03368 1.827 0.7224 2.556 1.082 0.8941 2.448 0.7531 3.151-0.4622 0.1243-0.5987 0.2883-1.547-0.8033-2.366l1.428-1.432z"
          />
          <path
            fill="#3f3f3f"
            d="m22.14 53.32a2.616 2.616 0 0 1-1.744-0.6514 3.057 3.057 0 0 1-0.885-2.558 3.264 3.264 0 0 1-1.881-0.6211 2.464 2.464 0 0 1-1.015-1.637 2.371 2.371 0 0 1 1.723-2.589 2.513 2.513 0 0 1 2.591 0.7324l1.56-1.56a0.5 0.5 0 0 1 0.707 0.7071l-1.978 1.978a0.4954 0.4954 0 0 1-0.4258 0.1416 0.5017 0.5017 0 0 1-0.3682-0.2573 1.524 1.524 0 0 0-1.81-0.78 1.399 1.399 0 0 0-1.008 1.483 1.476 1.476 0 0 0 0.62 0.978 2.36 2.36 0 0 0 1.802 0.3862 0.4855 0.4855 0 0 1 0.455 0.1543 0.5 0.5 0 0 1 0.1143 0.4668 2.265 2.265 0 0 0 0.4648 2.228 1.775 1.775 0 0 0 2.624-0.4522c0.0966-0.2139 0.3984-0.8769-0.8018-1.776a0.5 0.5 0 0 1-0.0537-0.7539l1.433-1.433a0.5 0.5 0 0 1 0.707 0.7071l-1.048 1.048a2.187 2.187 0 0 1 0.6719 2.626 2.641 2.641 0 0 1-1.867 1.371 2.867 2.867 0 0 1-0.5856 0.0615z"
          />
          <path
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m22.5 26.98-1.706-1.706s-1.231 1.076-2.186 0.8034c-0.8972-0.2526-1.346-0.9048-1.234-1.83 0.3393-1.319 1.618-2.026 2.937-1.687-0.3103-0.9535-0.07475-2.087 0.614-2.816 1.095-0.8427 2.375-0.5143 3.107 0.6574 0.8187 1.296-0.9798 2.446-0.9798 2.446 0.2046 0.2046 0.8494 0.796 1.668 1.683z"
          />
          <path
            fill="#3f3f3f"
            d="m22.5 27.49a0.498 0.498 0 0 1-0.3535-0.1465l-1.382-1.381a2.955 2.955 0 0 1-2.358 0.7583 2.378 2.378 0 0 1-1.722-2.609 2.46 2.46 0 0 1 1.013-1.619 3.264 3.264 0 0 1 1.881-0.6191 3.053 3.053 0 0 1 0.8818-2.555 2.52 2.52 0 0 1 2.047-0.6113 3.123 3.123 0 0 1 2.119 1.327 2.204 2.204 0 0 1-0.746 2.834c0.3056 0.2925 0.7324 0.71 1.238 1.259a0.5 0.5 0 1 1-0.7343 0.6777c-0.6114-0.6626-1.106-1.129-1.4-1.408l-0.2237-0.2148a0.5 0.5 0 0 1 0.0821-0.7734c0.0146-0.01 1.483-0.9771 0.94-1.837a2.105 2.105 0 0 0-1.413-0.8735 1.509 1.509 0 0 0-1.244 0.3672 2.235 2.235 0 0 0-0.4619 2.226 0.5 0.5 0 0 1-0.1143 0.4663 0.4935 0.4935 0 0 1-0.4551 0.1548 2.352 2.352 0 0 0-1.801 0.3847 1.467 1.467 0 0 0-0.6191 0.9585 1.404 1.404 0 0 0 1.007 1.504c0.5078 0.149 1.413-0.5014 1.77-0.8378a0.5 0.5 0 0 1 0.6972 0.01l1.706 1.706a0.5 0.5 0 0 1-0.3535 0.8535z"
          />
          <path
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m49.22 44.93c1.023 0.9552 1.637 1.724 1.842 1.929 0.4447-0.8727 1.363-1.26 2.301-0.9796 0.8972 0.2526 1.433 1.078 1.321 2.004-0.3388 1.319-1.705 1.875-3.024 1.535 0.3103 0.9535 0.1181 2.022-0.5706 2.751-1.082 0.8941-2.47 0.7098-3.172-0.5056-0.3411-0.7505-0.2015-1.634 0.8901-2.453l-1.433-1.432z"
          />
          <path
            fill="#3f3f3f"
            d="m49.79 53.32a2.869 2.869 0 0 1-0.5869-0.0615 2.641 2.641 0 0 1-1.865-1.367 2.191 2.191 0 0 1 0.67-2.63l-1.048-1.048a0.5 0.5 0 0 1 0.707-0.7071l1.434 1.434a0.5 0.5 0 0 1-0.0547 0.7529c-1.199 0.9-0.8984 1.562-0.8 1.78a1.776 1.776 0 0 0 2.626 0.4453 2.262 2.262 0 0 0 0.4609-2.225 0.5 0.5 0 0 1 0.1143-0.4668 0.4888 0.4888 0 0 1 0.4551-0.1543 2.372 2.372 0 0 0 1.802-0.3857 1.47 1.47 0 0 0 0.6182-0.958 1.402 1.402 0 0 0-1.006-1.504 1.526 1.526 0 0 0-1.81 0.78 0.5007 0.5007 0 0 1-0.7939 0.1167l-0.2187-0.2271c-0.292-0.3076-0.837-0.88-1.61-1.604a0.5 0.5 0 1 1 0.6816-0.7305c0.6377 0.5952 1.123 1.09 1.444 1.425a2.506 2.506 0 0 1 2.581-0.7226 2.376 2.376 0 0 1 1.721 2.61 2.459 2.459 0 0 1-1.013 1.617 3.271 3.271 0 0 1-1.88 0.62 3.054 3.054 0 0 1-0.8819 2.555 2.629 2.629 0 0 1-1.747 0.6549z"
          />
          <path
            fill="#3f3f3f"
            d="m49.63 27.42a0.5 0.5 0 0 1-0.3535-0.8535l1.636-1.637a0.5014 0.5014 0 0 1 0.6983-0.0112c0.3564 0.3369 1.253 0.9878 1.772 0.8383a1.399 1.399 0 0 0 1.008-1.483 1.476 1.476 0 0 0-0.6221-0.979 2.36 2.36 0 0 0-1.801-0.3847 0.5 0.5 0 0 1-0.5693-0.6216 2.265 2.265 0 0 0-0.4649-2.228 1.51 1.51 0 0 0-1.24-0.3638 2.107 2.107 0 0 0-1.416 0.877c-0.541 0.8564 0.9277 1.824 0.9424 1.834a0.5 0.5 0 0 1 0.082 0.7734l-1.501 1.501a0.5 0.5 0 0 1-0.707-0.7071l1.097-1.098a2.208 2.208 0 0 1-0.7578-2.84 3.122 3.122 0 0 1 2.122-1.33 2.522 2.522 0 0 1 2.043 0.6079 3.058 3.058 0 0 1 0.8857 2.559 3.264 3.264 0 0 1 1.88 0.6191 2.469 2.469 0 0 1 1.016 1.638 2.371 2.371 0 0 1-1.723 2.59 2.962 2.962 0 0 1-2.36-0.7583l-1.312 1.313a0.4981 0.4981 0 0 1-0.354 0.1463z"
          />
        </g>
        <g id="line">
          <path d="M67,56H5a1,1,0,0,1-1-1V17a1,1,0,0,1,1-1H67a1,1,0,0,1,1,1V55A1,1,0,0,1,67,56ZM6,54H66V18H6Z" />
        </g>
      </svg>
    );
  }
};

export default Flags;
