import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./SubMenu.scss";

// import { List, ListItem } from "@material-ui/core";

const SubMenu = ({ routes, test, navRoutes }) => {
  if (routes.title === test) {
    return (
      // <List>
      <div className="subMenu">
        <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.45602 2.07039C8.63103 0.190386 11.369 0.190389 12.544 2.07039L20 14H0L7.45602 2.07039Z"
            fill="black"
          />
        </svg>

        <ul className="menuBox">
          {routes.title === test &&
            routes.children.map((route, i) => {
              return (
                <li key={route.title} style={{ float: "none" }}>
                  <Link to={navRoutes.link + "#" + route.link}>
                    {route.title}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
      // </List>
    );
  } else {
    return "";
  }
};

export default SubMenu;
