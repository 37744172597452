import React from "react";
import { Grid } from "@material-ui/core";
import { HashLink as Link } from "react-router-hash-link";
import { makeStyles } from "@material-ui/core/styles";

import datas from "../exData/fakefooter.json";

const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="center" spacing={2}>
      {datas.routes.map((data) => {
        return (
          <Grid item key={data.title}>
            <Link to={data.path} className={classes.links}>
              {data.title}
            </Link>
          </Grid>
        );
      })}
      <Grid item>
        <a
          href="https://api.gaffenberg.de/fileadmin/footer/Datenschutzerklaerung.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.links}
        >
          Datenschutzerklärung
        </a>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles({
  links: {
    color: "white",
    padding: "5px",
  },
});

export default Footer;
