import React, { Suspense, lazy, useState, useEffect } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";

import { Grid } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const Pages = lazy(() => import("./components/Pages"));

const App = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [navData, setNavData] = useState([]);
  const [language, setLanguage] = useState("/");
  const [navRoutes, setNavRoutes] = useState([]);

  const THEME = createMuiTheme({
    typography: {
      fontFamily: `'Amaranth', sans-serif`,
      // fontSize: 14,
      // fontWeightLight: 300,
      // fontWeightRegular: 400,
      // fontWeightMedium: 500,
    },
  });

  useEffect(() => {
    if (language === "/") {
      fetch("https://api.gaffenberg.de/" + language + "?type=834")
        .then((res) => res.json())
        .then(
          (result) => {
            setNavData(result);
            setNavRoutes(result);
            setIsLoaded(true);
          },
          (error) => {
            setError(error);
            setIsLoaded(true);
          }
        );
    } else {
      fetch("https://api.gaffenberg.de/" + language + "?type=834")
        .then((res) => res.json())
        .then(
          (result) => {
            setNavData(result);
            setIsLoaded(true);
          },
          (error) => {
            setError(error);
            setIsLoaded(true);
          }
        );
      fetch("https://api.gaffenberg.de/?type=834")
        .then((res) => res.json())
        .then(
          (result) => {
            setNavRoutes(result);
            setIsLoaded(true);
          },
          (error) => {
            setError(error);
            setIsLoaded(true);
          }
        );
    }
  }, [language]);

  const updateLanguage = (lang) => {
    setLanguage(lang);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <MuiThemeProvider theme={THEME}>
        <Router>
          <Suspense fallback={<div>Lade...</div>}>
            <Grid container>
              <Grid container item>
                <Header
                  routes={navData.navigation[0].children}
                  navRoutes={navRoutes.navigation[0].children}
                  lang={navData.i18n} // languages
                  currentLanguage={language}
                  updateLanguage={updateLanguage}
                />
              </Grid>

              <Grid container item>
                <Switch>
                  <Route exact path="/" key="Gaff">
                    <Pages title="Gaff" link={language}></Pages>
                  </Route>
                  <Route exact path="/impressum" key="Impressum">
                    <Pages title="impressum" link={"/static/impressum"}></Pages>
                  </Route>
                  <Route
                    exact
                    path="/haftungsausschluss"
                    key="Haftungsauschluss"
                  >
                    <Pages
                      title="haftungsauschluss"
                      link={"/haftungsausschluss"}
                    ></Pages>
                  </Route>
                  {navData.navigation[0].children.map((route, index) => {
                    return (
                      <Route
                        exact
                        path={navRoutes.navigation[0].children[index].link}
                        key={route.title}
                      >
                        <Pages
                          title={route.title}
                          link={route.link}
                          childrens={route.children}
                        ></Pages>
                      </Route>
                    );
                  })}
                </Switch>
              </Grid>
              <Grid className="footer" style={{ zIndex: 20 }}>
                <Footer />
              </Grid>
            </Grid>
          </Suspense>
        </Router>
      </MuiThemeProvider>
    );
  }
};

export default App;
