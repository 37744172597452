import React, { useState, useEffect } from "react";
import logo from "../img/Logo.png";
import useDocumentScrollThrottled from "./useDocumentScrollThrottled";
import SubMenu from "./SubMenu";
import SubMenuLanguage from "./SubMenuLanguage";
// import Language from "./Language";
import PropTypes from "prop-types";
import { HashLink as Link } from "react-router-hash-link";
import Flags from "./Flags";
// import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

// import { Grid, List, ListItem } from "@material-ui/core";

import "./Header.scss";
import {
  IconButton,
  // Drawer,
  // Grid,
  // List,
  // ListItem,
  // Typography,
} from "@material-ui/core";

const Header = (props) => {
  const { routes, navRoutes, lang, currentLanguage, updateLanguage } = props;

  const [shouldBeSmall, setshouldBeSmall] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [menuPoint, setMenuPoint] = useState("");
  const TIMEOUT_DELAY = 0;
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  // const classes = useStyles();

  useDocumentScrollThrottled((callbackData) => {
    const { currentScrollTop } = callbackData;
    setTimeout(() => {
      setshouldBeSmall(currentScrollTop > 4);
    }, TIMEOUT_DELAY);
  });

  const shadowStyle = shouldBeSmall ? "small" : "";

  const showSubMenu = (key) => {
    setMenuPoint(key);
    setSubMenu(true);
  };
  const hideSubMenus = () => {
    setSubMenu(false);
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      setMobileView(true);
      setShowMenu(false);
    } else {
      setMobileView(false);
    }
  }, []);

  return (
    <div className={`${shadowStyle}`}>
      <div className="header">
        {mobileView && (
          <IconButton
            onClick={() => {
              setShowMenu(true);
            }}
          >
            <MenuIcon></MenuIcon>
          </IconButton>
        )}
        <Link to="/">
          <img src={logo} alt="Logo" className={"logo"} />
        </Link>
        <nav className={"links"}>
          <ul className="headerUl">
            {(!mobileView || showMenu === true) && (
              <>
                {routes.map((route, i) => {
                  return (
                    <li
                      className="link"
                      key={route.title}
                      onMouseOver={() => showSubMenu(route.title)}
                      onMouseLeave={hideSubMenus}
                    >
                      <Link
                        to={navRoutes[i].link}
                        onClick={() => {
                          setShowMenu(false);
                        }}
                      >
                        {route.title}
                      </Link>
                      {!mobileView && (
                        <div>
                          {route.children && subMenu && (
                            <SubMenu
                              routes={route}
                              navRoutes={navRoutes[i]}
                              test={menuPoint}
                            ></SubMenu>
                          )}
                        </div>
                      )}
                    </li>
                  );
                })}
              </>
            )}
            {!mobileView && (
              <li
                className="link"
                onMouseOver={() => setLanguageMenuOpen(true)}
                onMouseLeave={() => setLanguageMenuOpen(false)}
              >
                <div className="language">
                  <div className="flage">
                    <Flags language={currentLanguage}></Flags>
                  </div>
                  <div className="country">
                    {
                      lang.find((lan) => lan.link === currentLanguage)
                        .navigationTitle
                    }
                  </div>
                </div>
                <SubMenuLanguage
                  open={languageMenuOpen}
                  lang={lang}
                  currentLanguage={currentLanguage}
                  onSelection={(item) => {
                    updateLanguage(item);
                  }}
                ></SubMenuLanguage>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

// const useStyles = makeStyles({});

Header.propTypes = {
  routes: PropTypes.array,
  lang: PropTypes.array,
  updateLanguage: PropTypes.func,
};

export default Header;
